.banner-container {
    /* align-items: center; */
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 0.4rem 0.5rem rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    /* display: flex;
    flex-shrink: 0; */
    margin: .1rem;
    position: relative;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
}
@media only screen and (max-width: 600px) {
    .banner-container {
        
    height: 40rem;
    }
    .button-container{
        margin: auto;
    }
  }
.banner-text-container{
     align-items: center;
}

.edit-btn{
position:absolute;
top: 0px;
right: 0px;
}
.small-text{
    font-size: .7rem;
}
.button-container{
    position: absolute;
    bottom: 1rem;
    right: 0px;
}

.mask-group-Mu5 {
    flex-shrink: 0;
    height: 19.3354rem;
    margin: 0rem 1.5rem 0.892rem 0rem;
    object-fit: contain;
    position: relative;
    vertical-align: top;
    width: 27.4203rem;
}

.auto-group-khum-2VR {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: 1.4rem;
    width: 24.2rem;
}


.group-90-LW7 {
    flex-shrink: 0;
    height: 3rem;
    margin-bottom: 4.2rem;
    position: relative;
    width: 6.3rem;
}


.rectangle-140-RnT {
    background-color: #e6e6e6;
    border-radius: 1.5rem;
    height: 2.4rem;
    left: 0.3rem;
    position: absolute;
    top: 0.3rem;
    width: 6rem;
}

.edit-1-1-uhd {
    height: 3rem;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    vertical-align: top;
    width: 3rem;
}

.edit-2-1-2GT {
    height: 1.5rem;
    left: 0.8rem;
    object-fit: cover;
    position: absolute;
    top: 0.8rem;
    vertical-align: top;
    width: 1.5rem;
}

.edit-WhR {
    color: #756ce0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 0.9rem;
    font-weight: 500;
    height: 1.4rem;
    left: 3.3rem;
    line-height: 1.5;
    position: absolute;
    top: 0.8rem;
    white-space: nowrap;
    width: 1.7rem;
}

.auto-group-ccfd-BoZ {
    flex-shrink: 0;
    height: 4.8rem;
    margin: 0rem 9.1rem 0.5rem 0rem;
    position: relative;
    width: 15.1rem;
}

.education-meterials-swH {
    color: #000000;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 1.5rem;
    font-weight: 600;
    height: 2.9rem;
    left: 0;
    line-height: 1.9333333333;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 15.1rem;
}

.study-meterials-on-offer-xhq {
    color: #756ce0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 1rem;
    font-weight: 500;
    height: 2.9rem;
    left: 0;
    letter-spacing: 0.02rem;
    line-height: 2.9;
    position: absolute;
    top: 1.9rem;
    white-space: nowrap;
    width: 12.6rem;
}

.you-are-doing-greate-lests-start-our-next-topic-RrK {
    color: #000000;
    flex-shrink: 0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 0.8rem;
    line-height: 1.5;
    margin: 0rem 11.2rem 3.8rem 0rem;
    max-width: 13rem;
}

.auto-group-cwdd-6Bm {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 3.4rem;
    margin: 0rem 1.4rem 0rem 8.4rem;
    width: calc(100% - 9.8rem);
}

.group-100-PAs {
    border: solid 0.1rem #ff2828;
    border-radius: 1rem;
    box-sizing: border-box;
    flex-shrink: 0;
    height: 100%;
    margin-right: 1rem;
    padding: 0.8rem;
}

.bin-1-hhM {
    height: 1.8rem;
    object-fit: cover;
    vertical-align: top;
    width: 1.8rem;
}

.component-10-b23 {
    cursor: pointer;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    width: 10rem;
}

.publish-KTq {
    color: #756ce0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 1.2rem;
    font-weight: 500;
    height: 1.8rem;
    left: 2.8rem;
    line-height: 1.5;
    position: absolute;
    top: 0.8rem;
    white-space: nowrap;
    width: 4.4rem;
}

.rectangle-139-aef {
    border: solid 0.1rem #756ce0;
    border-radius: 1rem;
    box-sizing: border-box;
    height: 3.4rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 10rem;
}