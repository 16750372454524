.group-93-hm1 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 0.3rem 2rem rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    margin-bottom: 2rem;
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-shrink: 0; */
    /* height: 100%; */
    /* margin-right: .3rem; */
}
.group-93-hm1{
    padding: .7rem .7rem 0.7rem .7rem;
    /* width: 36.1rem; */
}

.card-header-img{
    padding: 0%;
    margin: 0;
    border-radius: .5rem;
}

.auto-group-smp5-111 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 1rem;
    box-sizing: border-box;
    flex-shrink: 0;
    height: 16rem;
    margin-bottom: 0.9rem;
    padding: 0rem 0.3rem 0rem 27.4rem;
    width: 100%;
}

.group-90-VRy {
    cursor: pointer;
    height: 3rem;
    position: relative;
    width: 100%;
}

.rectangle-140-SMD {
    background-color: #ffffff;
    border-radius: 1.5rem;
    height: 2.4rem;
    left: 0.3rem;
    position: absolute;
    top: 0.3rem;
    width: 6rem;
}

.edit-1-1-ksh {
    height: 3rem;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    vertical-align: top;
    width: 3rem;
}

.edit-2-1-JPR {
    height: 1.5rem;
    left: 0.8rem;
    object-fit: cover;
    position: absolute;
    top: 0.8rem;
    vertical-align: top;
    width: 1.5rem;
}

.edit-SEj {
    color: #8f8f8f;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 0.9rem;
    font-weight: 500;
    height: 1.4rem;
    left: 3.5rem;
    line-height: 1.5;
    position: absolute;
    top: 0.8rem;
    white-space: nowrap;
    width: 1.7rem;
}

.auto-group-3vk5-iCF {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 5.8rem;
    margin-bottom: 0.2rem;
    width: 100%;
}

.auto-group-msvd-rZM {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    margin-right: 8rem;
}

.uiux-designing-bG3 {
    color: #3e3e3e;
    flex-shrink: 0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.8rem;
    white-space: nowrap;
}

.s-simply-dummy-text-of-the-printing-and-typesetting-industry-Khq {
    color: #8f8f8f;
    flex-shrink: 0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    max-width: 16rem;
}

.group-85-BEF {
    background-color: #756ce0;
    border-radius: 1.5rem;
    box-sizing: border-box;
    flex-shrink: 0;
    height: calc(100% - 0.8rem);
    margin: 0.2rem 0rem 0.6rem 0rem;
    padding: 2.5rem 4.3rem;
}

.line-35-tuM {
    height: 0;
    object-fit: contain;
    vertical-align: top;
    width: 1.4rem;
}

.auto-group-wz43-ReP {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 3.4rem;
    width: 100%;
}

.auto-group-tfsm-my9 {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    padding: 1.4rem 11rem 0.4rem 0rem;
}

.document-2-hro {
    flex-shrink: 0;
    height: 1.2rem;
    margin: 0rem 0.4rem 0.4rem 0rem;
    object-fit: cover;
    vertical-align: top;
    width: 1.2rem;
}

.lesson-qTD {
    color: #8f8f8f;
    flex-shrink: 0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.5;
    margin: 0.2rem 2.3rem 0rem 0rem;
    white-space: nowrap;
}

.star-1-1-m5y {
    flex-shrink: 0;
    height: 1rem;
    margin: 0rem 0.4rem 0.2rem 0rem;
    object-fit: cover;
    vertical-align: top;
    width: 1rem;
}

.item-42199-tgP {
    color: #8f8f8f;
    flex-shrink: 0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 0.2rem;
    white-space: nowrap;
}

.component-10-ppw {
    cursor: pointer;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    width: 10rem;
}

.publish-mEP {
    color: #756ce0;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 1.2rem;
    font-weight: 500;
    height: 1.8rem;
    left: 2.8rem;
    line-height: 1.5;
    position: absolute;
    top: 0.8rem;
    white-space: nowrap;
    width: 4.4rem;
}

.rectangle-139-eJB {
    border: solid 0.1rem #756ce0;
    border-radius: 1rem;
    box-sizing: border-box;
    height: 3.4rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 10rem;
}