
.rectangle-117-Qdm {
    background-color: #ffffff;
    border-radius: 1.8rem;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.25);
    margin-bottom: 2rem;
}

.auto-group-srjz-uDH {
    align-items: center;
    background-color: #ffffff;
    border-radius: 1.8rem;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.25);

}

.dashboard-relative {
    position: relative;
    background-color: red;
}

.k-today-iTR {
    margin-top: -20px;
    color: #7d7d7d;
    font-family: Poppins, 'Source Sans Pro';
    display: block;
    position: absolute;
    right: 0;
    font-size: 0.9rem;
    font-weight: 400;
    white-space: nowrap;
}

.k-today-iTR-sub-0 {
    color: #1dd6ff;
    font-family: Poppins, 'Source Sans Pro';
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 2;
}


.auto-group-bnxo-Ce3 {
    border-radius: 1.8rem;
    padding: 1.9rem 3rem 1.9rem 3rem;
}

